import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"
import { scrollToRef } from "../utils/dom-utils"
import { globalHistory } from "@reach/router"
import { GatsbyImage } from "gatsby-plugin-image"
import Email from "../components/email"
import Phone from "../components/phone"

const GastronomiePage = ({ location, data }) => {
  const mieteRef = useRef(null)

  const navigateToHash = hash => {
    if (!hash) return
    switch (hash) {
      case "#miete":
        scrollToRef(mieteRef)
        break
      default:
        break
    }
  }

  setTimeout(() => navigateToHash(location.hash), 100)

  useEffect(() => {
    // whenever the route changes we change the tab depending on the hash of the url
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hash = globalHistory.location.hash
        navigateToHash(hash)
      }
    })
  })

  return (
    <Layout>
      <Seo title="Gastronomie" />
      <section
        id="banner-gastro"
        className="banner d-flex flex-column justify-content-end"
      >
        <Container
          className="text-center text-md-left"
          style={{ maxWidth: "900px" }}
        >
          <h1>Gastronomie</h1>
        </Container>
      </section>
      <Container
        className="text-center text-sm-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <p>
            Entspannen Sie nach dem Spiel bei erfrischenden Getränken und
            belebenden Speisen in unserer Gastronomie!
          </p>
          <div
            className={`d-flex mt-5 flex-column align-items-center flex-md-row justify-content-md-between`}
          >
            <GatsbyImage
              style={{
                float: "left",
                height: "300px",
                width: "350px",
                objectFit: "cover",
              }}
              image={data.gastroImg02.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              style={{
                float: "left",
                height: "300px",
                width: "350px",
                objectFit: "cover",
              }}
              image={data.gastroImg01f.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              style={{
                float: "left",
                height: "300px",
                width: "350px",
                objectFit: "cover",
              }}
              image={data.gastroImg06f.childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="mt-5 d-flex flex-column flex-sm-row">
            <table className="mt-sm-0 text-sm-left">
              <thead>
                <tr>
                  <th colSpan={2}>Öffnungszeiten in der Sommersaison:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Montags - Donnerstags</td>
                  <td>17:30 - 23:00 Uhr</td>
                </tr>
                <tr>
                  <td>Freitags</td>
                  <td>Ruhetag, außer an Veranstaltungen</td>
                </tr>
                <tr>
                  <td>Samstag/Sonntag</td>
                  <td>ab 11.00 nach Bedarf</td>
                </tr>
              </tbody>
            </table>
            {/*
            <table className="text-sm-left">
              <thead>
              <tr><th colSpan={2}>Öffnungszeiten in der Wintersaison:</th></tr>
              </thead>
              <tbody>
              <tr><td>Mo. - Do.</td><td>ab 19 Uhr</td></tr>
              <tr><td>Freitags</td><td>Ruhetag, außer an Veranstaltungen</td></tr>
              <tr><td>Am Wochenende</td><td>ab 19 Uhr</td></tr>
              <tr>Weitere Öffnungszeiten nach Bedarf<td></td><td></td></tr>
              </tbody>
            </table>
          */}
          </div>
          <p>
            <br></br>
            Genießen Sie wechselnde Gerichte und diverse Kleinigkeiten. Gerne
            nimmt die Gastro auch Bestellungen für zu Hause entgegen, wie
            Buffets, Kuchen etc. Bitte immer nachfragen, es wird versucht, alles
            möglich zu machen.
          </p>
          <div className="d-flex flex-column align-items-center flex-md-row align-items-md-start">
            <div className="mr-md-5">
              <p>
                <b>Kontakt:</b>
                <br />
              </p>
              Ulrike Beckmann
              <br />
              <Email email="gastro@wthc-volksdorf.de" />
              <br />
              <Email email="beckmann.ammersbek@freenet.de" />
              <br />
              <Phone phone="0157 30 19 22 33" />
              <br />
            </div>
            <GatsbyImage
              className=""
              style={{
                float: "left",
                height: "200px",
                width: "350px",
                maxHeight: "200px",
                maxWidth: "350px",
                objectFit: "cover",
              }}
              image={data.gastroImg05.childImageSharp.gatsbyImageData}
            />
          </div>
        </section>
        <section>
          <h2 ref={mieteRef}>Miete der Clubräume</h2>
          <div className="d-flex flex-column align-items-center flex-md-row align-items-md-start">
            <div className="mr-md-5">
              <p>
                Für private Feiern kann das Clubhaus über Frau Ulrike Beckmann
                gebucht werden. Die Speisen werden von Frau Beckmann nach
                Kundenwunsch zubereitet.
              </p>
              <p>
                Bei Veranstaltungen mit 20-30 Personen erhebt der Club eine
                Nutzungspauschale von:
              </p>
              <p>
                € 75,-- für Vereinsmitglieder
                <br />€ 150,-- für Nichtmitglieder
              </p>
              <p>
                Ab dem 31. Teilnehmer werden zusätzlich € 3,-- für jede weitere
                Person berechnet.
              </p>
              {/* <p>
                <b>Kontakt:</b><br/>
                Ulrike Beckmann<br/>
                <Email email="gastro@wthc-volksdorf.de"/><br/>
                <Email email="beckmann.ammersbek@freenet.de"/><br/>
                <Phone phone="0157 30 19 22 33"/><br/>
              </p> */}
              <p></p>
            </div>
            {/* <Img className="" style={{
              float: 'left',
              height: '200px',
              width: '350px',
              maxHeight: '200px',
              maxWidth: '350px',
              objectFit: 'cover'
            }} fluid={data.gastroImg05.childImageSharp.fluid}/> */}
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    feierImg: file(relativePath: { eq: "feier.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg01: file(relativePath: { eq: "gastro01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg02: file(relativePath: { eq: "gastro02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg03: file(relativePath: { eq: "gastro03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg04: file(relativePath: { eq: "gastro04.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg05: file(relativePath: { eq: "gastro05.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg01f: file(relativePath: { eq: "gastro01f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg02f: file(relativePath: { eq: "gastro02f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg03f: file(relativePath: { eq: "gastro03f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg04f: file(relativePath: { eq: "gastro04f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg05f: file(relativePath: { eq: "gastro05f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg06f: file(relativePath: { eq: "gastro06f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastroImg07f: file(relativePath: { eq: "gastro07f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    anlageImgshaehands: file(relativePath: { eq: "anlageshakeshands.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`

export default GastronomiePage
